.headline {
  margin: 32px 0;
  font-weight: 800;
  font-family: 'Manrope';
  color: #bca36b;
  line-height: 1.2;

  @screen xl {
    margin: 50px 0;
  }
}

.headline_no_margin {
  margin: auto;
}

.headline_mb_only {
  margin-top: 0;
}

.headline__h1 {
  font-size: 48px;
  /* background: radial-gradient(circle, rgb(255, 231, 174) 0%, #bca36b 80%); */
  /* background-clip: text;
  -webkit-text-fill-color: transparent; */

  br {
    display: none;
  }

  @screen md {
    font-size: 64px;

    br {
      display: block;
    }
  }

  @screen lg {
    font-size: 48px;
  }

  @screen xl {
    font-size: 60px;
  }
}

.headline__h2 {
  font-size: 36px;
  /* background: radial-gradient(circle, rgb(255, 231, 174) 0%, #bca36b 80%);
  background-clip: text;
  -webkit-text-fill-color: transparent; */

  @screen md {
    font-size: 42px;
  }

  @screen xl {
    font-size: 48px;
  }
}

.headline__h3 {
  font-size: 20px;
  font-weight: 700;
  color: white;

  @screen md {
    font-size: 26px;
  }
}

.headline__h4 {
  font-size: 11px;
  font-weight: 600;
  color: white;

  @screen md {
    font-size: 15px;
  }
}

.headline__h5 {
  font-size: 28px;
  line-height: 1.4;
  font-weight: 800;
  color: #bca36b;

  @screen lg {
    font-size: 34px;
  }
}
